import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/axios';

export const placeBet = createAsyncThunk(
  'bet/postBet',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiClient.request({
        url: process.env.REACT_APP_BET_ROUTE,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const betSlice = createSlice({
  name: "bet",
  initialState: null,
  reducers: {
    clearBet(state) {
      state = null;

      return state;
    },
    setActiveBet(state, action) {
      state = action.payload;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeBet.fulfilled, (state, action) => {
        state = action.payload;
        state.loading = false;

       return state;
      })
    } 
  });

export const { setActiveBet, clearBet } = betSlice.actions;
export default betSlice.reducer;
