import { urlParams } from "../get-url-params";
import { acceptedLang } from "./accepted-langs";
import storage from "../storage/storage";

function randomIntFromTo(min, max) {return Math.floor(Math.random() * (max - min + 1) + min)}

export const loginParams = {
  "company_id": urlParams.get('companyId') || 1,
  "uid": urlParams.get('uid') || "rnd" + randomIntFromTo(1, 10000) + "x" + randomIntFromTo(1, 10000) + "",
  "currency_code": urlParams.get('currency') || 'EUR',
  "token": urlParams.get('token') || null,
  "language_code": acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en",
  "country_code": urlParams.get('countryCode'),
  "avatar_id": 0,
  "version": urlParams.get('version') || null,
  "auto_bet": urlParams.get('autobet') || false,
  "auto_cashout": urlParams.get('autocashout') || false,
  "hide_header": urlParams.get('hideHeader') || false,
  "show_help": urlParams.get('showHelp') || false,
  "fullscreen": urlParams.get('fullscreen') || false,
  "crypto": urlParams.get('crypto') || false,
  "back_url": urlParams.get('backUrl') || "",
  "chat_room": urlParams.get('chatRoom') || 'en',
  "launchId": urlParams.get('launchId') || null,
  "userDefinedUsername": storage.get('userDefinedUsername') || ''
}
